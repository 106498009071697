<template>
  <h1>Administrar Tarjetas</h1>


  
</template>

<script>
export default {

}
</script>

<style>

</style>